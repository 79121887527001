<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="card mb-3">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div :class="platform == 'desktop' ? 'col' : 'col-sm'">
                                <h4 class="mb-0" :class="{'text-center': platform == 'mobile'}">Pagamento do sistema</h4>
                            </div>
                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-sm mt-3'">
                                <button class="btn btn-block btn-success" :disabled="loading || loadingPayment || !client_id || paymentGerenateSuccess" @click="generatePayment">
                                    <i class="fas fa-usd-circle mr-2"></i>Gerar pagamento
                                </button>
                            </div>
                        </div>
                    </div><!-- /card-header -->
                    <div class="card-body">
                        <template v-if="loading && !error">
                            <div class="alert alert-warning mb-0" role="alert">
                                <div class="alert-heading text-center mb-0">
                                    <div class="spinner-border mr-2 align-middle" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <span>Carregando, aguarde...</span>
                                </div>
                            </div><!-- /alert-warning -->
                        </template>
                        <template v-else-if="!loading && error">
                            <div class="alert alert-danger mb-0" role="alert">{{ error }}</div>
                        </template>
                        <template v-else>
                            <div class="alert mb-3" :class="paymentSuccess ? 'alert-success' : 'alert-warning'" role="alert">
                                <div class="d-flex align-items-center justify-content-center" :class="{'flex-column': !loadingPayment}">
                                    <template v-if="loadingPayment">
                                        <div class="spinner-border mr-2 align-middle" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <span>Gerando pagamento, aguarde...</span>
                                    </template>
                                    <template v-else-if="paymentGerenateSuccess">
                                        <div class="text-center mb-3">
                                            <img class="rounded" :src="qrcode.image" width="225" height="225" border="0" alt="QR Code">
                                        </div><!-- /text-center -->
                                        <span>Escanei o <b>QR Code</b> com o app do seu banco ou {{ platform == 'desktop' ? 'clique' : 'aperte' }} no campo abaixo para copiar o código do pix.</span>
                                        <div class="input-group mt-3">
                                            <div class="input-group-prepend" role="button" @click="copyClipboard('pix-qrcode')">
                                                <span class="input-group-text" id="inputGroupPrepend3">
                                                    <i class="fas fa-copy mr-2"></i>Copiar
                                                </span>
                                            </div><!-- /input-group-prepend -->
                                            <input class="form-control" id="input-pix" ref="pix-qrcode" type="text" :value="qrcode.code" autocomplete="off" spellcheck="false" readonly>
                                        </div><!-- /input-group -->
                                        <hr />
                                        <p class="mb-0">Tentativas: <strong>{{ pay_total_check_count }}/{{ pay_total_check_limit }}</strong></p>
                                        <p class="mb-0">Status do pagamento: <strong>{{ pay_status == 'pending' ? 'Pendente' : 'Aprovado' }}</strong></p>
                                    </template>
                                    <template v-else>
                                        <template v-if="paymentSuccess">
                                            <div class="display-3">
                                                <i class="fas fa-badge-check"></i>
                                            </div>
                                            <div>Pagamento efetuado com sucesso!</div>
                                        </template>
                                        <template v-else>
                                            <div class="display-3">
                                                <i class="fas fa-engine-warning"></i>
                                            </div>
                                            <div>{{ message }}</div>
                                        </template>
                                    </template>
                                </div>
                            </div><!-- /alert -->
                            <div class="row">
                                <div class="col-sm-3">Data de adesão</div>
                                <div class="col-sm-9 text-muted">{{ data_inicio }}</div>
                            </div><!-- /row -->
                            <hr />
                            <div class="row">
                                <div class="col-sm-3">Data de pagamento</div>
                                <div class="col-sm-9 text-muted">{{ data_pagamento }}</div>
                            </div><!-- /row -->
                            <hr />
                            <div class="row">
                                <div class="col-sm-3">Status pagamento</div>
                                <div class="col-sm-9" :class="{'text-success': situacao == 'Pagamento em dia','text-warning': situacao == 'Pagamento pendente', 'text-danger': situacao == 'Desativado'}">{{ situacao }}</div>
                            </div><!-- /row -->
                            <hr />
                            <div class="row">
                                <div class="col-sm-3">Mensalidade</div>
                                <div class="col-sm-9 text-muted">R$ {{ mensalidade }}</div>
                            </div><!-- /row -->
                            <hr />
                            <div class="row">
                                <div class="col-sm-3">Modalidades</div>
                                <div class="col-sm-9 text-muted text-uppercase">{{ modalidades }}</div>
                            </div><!-- /row -->
                        </template>
                    </div><!-- /card-body -->
                </div><!-- /card -->
            </div><!-- /container -->
        </div><!-- /main -->
    </main>
</template>

<script>
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

export default {
    data() {
        return {
            pay_id: 0,
            error: '',
            message: '',
            situacao: '',
            client_id: '',
            qrcode: {
                code: '',
                image: ''
            },
            loading: false,
            loadingPayment: false,
            paymentSuccess: false,
            paymentGerenateSuccess: false,
            pay_status: 'pending',
            pay_total_check_count: 0,
            pay_total_check_limit: 50,
            mensalidade: 0,
            modalidades: '',
            data_inicio: '',
            data_pagamento: '',
            situacao_pagamento: {
                'disabled': 'Desativado',
                'remember': 'Pagamento pendente',
                'no-remember': 'Pagamento em dia'
            },
            url: 'www.valsports.net' || window.location.hostname,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
        }
    },  
    components: {
        Navbar
    },
    methods: {
        checkPayment() {

            const self = this;

            self.error = '';
            self.message = '';
            self.loading = true;
            self.qrcode.code = '';
            self.qrcode.image = '';
            self.paymentSuccess = false;
            self.pay_status = 'pending';
            self.pay_total_check_count = 0;
            self.paymentGerenateSuccess = false;
            
            api.post('dashboard/check-payment', {
                url: self.url,
                pay_id: self.pay_id,
                action: 'check-payment',
                client_id: self.client_id
            }).then((response) => {
                switch(response.data.result) {
                    case 'disabled':
                        self.error = 'Sistema desativado, entre em contato para reativar!';
                    break;
                    default:
                        self.client_id = response.data.id;
                        self.message = response.data.message;
                        self.data_inicio = response.data.start_date;
                        self.data_pagamento = response.data.date_full;
                        self.mensalidade = self.format_coin(response.data.price);
                        self.modalidades = self.getPackage(response.data.package);
                        self.situacao = self.situacao_pagamento[response.data.result];
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        async generatePayment() {

            const self = this;

            if (!self.client_id) {
                window.location.reload();
            } else {
                
                const { value: formValues } = await Swal.fire({
                    title: 'Gerar pagamento',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Pagar',
                    html: `
                        <label class="d-block text-left mb-0">Nome completo</label>
                        <input id="swal-input1" class="swal2-input" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">CPF (Apenas números)</label>
                        <input id="swal-input2" class="swal2-input" maxlength="11" autocomplete="off" spellcheck="false">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            client_name: document.getElementById('swal-input1').value,
                            client_document: document.getElementById('swal-input2').value
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú\s]+/gi, '');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                    }
                });

                if (formValues.client_name.toString().trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe seu nome completo!',
                        'warning'
                    );
                } else if (formValues.client_document.toString().trim() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe seu CPF!',
                        'warning'
                    );
                } else if (!self.isValidCPF(formValues.client_document)) {
                    Swal.fire(
                        'Atenção!',
                        'O CPF informado é inválido!',
                        'warning'
                    );
                } else {

                    self.pay_id = 0;
                    self.message = '';
                    self.qrcode.code = '';
                    self.qrcode.image = '';
                    self.loadingPayment = true;
                    self.paymentSuccess = false;
                    self.pay_status = 'pending';
                    self.pay_total_check_count = 0;
                    self.paymentGerenateSuccess = false;
                    
                    api.post('dashboard/check-payment', {
                        url: self.url,
                        pay_id: self.pay_id,
                        action: 'generate-payment',
                        client_id: self.client_id,
                        client_name: formValues.client_name,
                        client_document: formValues.client_document
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.paymentGerenateSuccess = true;
                                self.pay_id = response.data.pay_id;
                                self.qrcode.code = response.data.qrcode.code;
                                self.qrcode.image = response.data.qrcode.image;
                            break;
                            default:
                                self.message = response.data.message;
                            break;
                        }
                    }).catch((error) => {
                        try {
                            self.message = self.network_erros[error.status]['message'];
                        } catch(e) {
                            self.message = self.network_erros[408]['message'];
                        }
                    }).finally(() => {
                        self.loadingPayment = false;
                    });
                }
            }
        },
        checkPaymentPix() {

            const self = this;

            if (!self.client_id) return;

            api.post('dashboard/check-payment', {
                url: self.url,
                pay_id: self.pay_id,
                action: 'check-payment-status',
                client_id: self.client_id
            }).then((response) => {
                switch(response.data.result) {
                    case 'success':
                    case 'pending':
                        self.checkPaymentStatus(response.data);
                    break;
                    default:
                        self.$dialogs.alert(response.data.message, {
                            title: 'Falha',
                            size: 'sm'
                        });
                    break;
                }
            }).catch((error) => {
                try {
                    self.$dialogs.alert(self.network_erros[error.status]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                } catch(e) {
                    self.$dialogs.alert(self.network_erros[408]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                }
            });
        },
        checkPaymentStatus(data) {

            this.pay_total_check_count += 1;

            if (data.result == 'success') {
                this.pay_id = 0;
                this.qrcode.code = '';
                this.qrcode.image = '';
                this.paymentSuccess = true;
                this.pay_status = 'approved';
                this.pay_total_check_count = 0;
                this.paymentGerenateSuccess = false;
                this.data_pagamento = data.data_pagamento;
                this.situacao = this.situacao_pagamento['no-remember'];
            } else if (data.result == 'pending' && (this.pay_total_check_count <= this.pay_total_check_limit)) {
                setTimeout(this.checkPaymentPix, 5000);
            }
        },
        getPackage(modalities) {
            try {
                if (modalities.length > 1) {
                    let txt = '';
                    let count = 0;
                    let len = modalities.length;
                    for (const modality of modalities) {
                        txt += modality;
                        if (count < len - 1) {
                            txt += ', ';
                        }
                        count++;
                    }
                    return txt;
                } else {
                    return modalities[0];
                }
            } catch(e) {
                return modalities.toString();
            }
        },
        isValidCPF(cpf) {

            var add;
            var rev;

            cpf = cpf.replace(/[^\d]+/g,'');  

            if(cpf == '') return false; 
            // Elimina CPFs invalidos conhecidos  
            if (cpf.length != 11 || 
                cpf == "00000000000" || 
                cpf == "11111111111" || 
                cpf == "22222222222" || 
                cpf == "33333333333" || 
                cpf == "44444444444" || 
                cpf == "55555555555" || 
                cpf == "66666666666" || 
                cpf == "77777777777" || 
                cpf == "88888888888" || 
                cpf == "99999999999")
                return false;   
            // Valida 1o digito 
            add = 0;  
            for (var i=0; i < 9; i ++)    
                add += parseInt(cpf.charAt(i)) * (10 - i);  
                rev = 11 - (add % 11);  
                if (rev == 10 || rev == 11)   
                rev = 0;  
                if (rev != parseInt(cpf.charAt(9)))   
                return false;   
            // Valida 2o digito 
            add = 0;  
            for (var j = 0; j < 10; j ++)   
                add += parseInt(cpf.charAt(j)) * (11 - j);  
            rev = 11 - (add % 11);  
            if (rev == 10 || rev == 11) 
                rev = 0;  
            if (rev != parseInt(cpf.charAt(10)))
                return false;   
            return true;       
        },
        copyClipboard(ref) {
            try {
                navigator.clipboard.writeText(this.$refs[ref]['value']).then(() => {
                    this.$dialogs.alert('O <strong>código</strong> foi copiado com sucesso!', {
                        title: 'Parabéns',
                        size: 'sm'
                    });
                }, () => {
                    this.$dialogs.alert('Não foi possível copiar o <strong>link</strong>!', {
                        title: 'Falha',
                        size: 'sm'
                    });
                });
            } catch(e) {
                this.$dialogs.alert('Ocorreu um erro, atualize a página e tente novamente!', {
                    title: 'Falha',
                    size: 'sm'
                });
            }
        },
    },
    watch: {
        paymentGerenateSuccess(value) {
            if (value) {
                this.checkPaymentPix();
            }
        }
    },
    created() {
        this.checkPayment();
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
</style>